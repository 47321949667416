import React from "react";
import { Container } from "react-bootstrap";

function Projects() {
  return (
    <Container fluid className="project-section" style={{ paddingBottom: 0 }}>
      <Container style={{ paddingBottom: 0, marginBottom: 0 }}>
        {/* Title for the section */}
        <h1 className="project-heading" style={{ marginBottom: "19px" }}>
          Things She's <strong className="purple">Done</strong>
        </h1>
        
        {/* Paragraph placeholder for your content */}
        <p style={{ color: "white", fontSize: "18px", lineHeight: "1.7" }}>
          {/* Paste your custom paragraph here */}
          In her many years, the Wife of Bath has seen much and done even more. She’s had five husbands, each one taught her something new, and with each marriage, she grew wiser and bolder. She’s traveled to many lands, seen the world from Venice to Jerusalem, and with every journey, her knowledge of both men and the world deepened. But it’s not just men that have taught her; it’s the work of her hands. She’s mended and made, crafted and built—much like her own life, which has been patched together from experience. She’s worked with skill, creating and managing, never afraid to speak her mind or take control. Through every project, every task, she’s learned the value of independence and the strength that comes from doing what must be done. A woman of many accomplishments, she’s never let anyone tell her what she can or cannot do, for her experience has been her guide, and it has shaped her into who she is today.
            <br></br>
            <br></br><br></br>
            <br></br>
            <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
        </p>
      </Container>
    </Container>
  );
}

export default Projects;

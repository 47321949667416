import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AiFillGithub } from "react-icons/ai"; // Only keep GitHub icon

function Footer() {
  let date = new Date();
  let year = date.getFullYear();

  return (
    <footer
      className="footer"
      style={{
        backgroundColor: "#222",
        color: "white",
        padding: "20px 0",
        textAlign: "center",
        position: "relative", // Keeps footer at the bottom
        width: "100%",
        bottom: -500, // Makes sure footer is positioned at the bottom
        marginTop: "auto", // Pushes footer down if content is short
      }}
    >
      <Container fluid>
        <Row>
          <Col md="4" className="footer-copywright">
            <h3>Designed by Tyler Payne, Developed by Gage Milner</h3>
          </Col>
          <Col md="4" className="footer-copywright">
            <h3>Copyright © {year} Gage Milner</h3>
          </Col>
          <Col md="4" className="footer-body">
            <ul className="footer-icons" style={{ listStyleType: "none", padding: 0 }}>
              {/* GitHub Button Only */}
              <li className="social-icons" style={{ display: "inline", margin: "0 10px" }}>
                <a
                  href="https://github.com/LockBorn1/wifebathfinal/tree/main" // Updated GitHub link
                  style={{ color: "white" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AiFillGithub size={30} /> {/* You can adjust the size here */}
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
